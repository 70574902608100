<template>
  <div>
    main
  </div>
</template>

<script>


export default {
  name: 'Main',
  created () {
  },
  components: {

  },
  computed: {
    backgroundColor () {
      return '#000000'
    }
  }
}
</script>
